import './Orbit.css';
import { useEffect, useRef, useState } from 'react';
import UranusElectronImage from './UranusElectronImage.png';
import JupiterElectronImage from './JupiterElectronImage.png';
import EarthElectronImage from './EarthElectronImage.png';
import SunElectronImage from './SunElectronImage.png';

interface Dimensions {
  galaxyHeight: number;
  galaxyTop: number;
  electron: number;
  sun: number;
  contentTop: number;
  title: number;
  tagline: number;
  para: number;
  btnW: number;
  btnH: number;
  btnMarginTop: number;
  orbitDimensions: {
    uranus: { width: number; margin: number };
    jupiter: { width: number; margin: number };
    earth: { width: number; margin: number };
    sun: { width: number; margin: number };
  };
}

const calculateDimension = (
  initialWidth: number,
  initialHeight: number
): number => {
  return (window.innerWidth / initialWidth) * initialHeight;
};

const initialDimensions: Dimensions = {
  galaxyHeight: calculateDimension(1800, 1755) + 100,
  galaxyTop: calculateDimension(1706, -144),
  electron: (80 * window.innerWidth) / 1689,
  sun: calculateDimension(10240, 1321.79),
  contentTop: calculateDimension(1498.89, 134) + 50,
  title: calculateDimension(1498.89, 62),
  tagline: calculateDimension(1498.89, 521),
  para: calculateDimension(1498.89, 20),
  btnMarginTop: calculateDimension(1498.89, 62),
  btnW: calculateDimension(1498.89, 190),
  btnH: calculateDimension(1498.89, 44),
  orbitDimensions: {
    uranus: {
      width: 1400 * (window.innerWidth / 1800),
      margin: (1400 * (window.innerWidth / 1800)) / 2,
    },
    jupiter: {
      width: 1159 * (window.innerWidth / 1800),
      margin: (1159 * (window.innerWidth / 1800)) / 2,
    },

    earth: {
      width: 859 * (window.innerWidth / 1800),
      margin: (859 * (window.innerWidth / 1800)) / 2,
    },
    sun: {
      width: 500 * (window.innerWidth / 1800),
      margin: (500 * (window.innerWidth / 1800)) / 2,
    },
  },
};

const Orbit = () => {
  const [dimensions, setDimensions] = useState<Dimensions>(initialDimensions);
  const sunRef = useRef(null);
  const uranusCounter = useRef(190);
  const jupiterCounter = useRef(210);
  const earthCounter = useRef(230);
  const sunCounter = useRef(260);

  const timers = useRef<NodeJS.Timeout[]>([]);

  const handleDimensions = () => {
    const newDimensions: Dimensions = {
      ...initialDimensions,
      galaxyTop: calculateDimension(1706, -144),
      galaxyHeight: calculateDimension(1800, 1755) + 100,
      electron: (80 * window.innerWidth) / 1689,
      sun: calculateDimension(10240, 1321.79),
      contentTop: calculateDimension(1498.89, 134) + 50,
      title: calculateDimension(1498.89, 62),
      tagline: calculateDimension(1498.89, 521),
      para: calculateDimension(1498.89, 20),
      btnMarginTop: calculateDimension(1498.89, 62),
      btnW: calculateDimension(1498.89, 190),
      btnH: calculateDimension(1498.89, 44),
      orbitDimensions: {
        uranus: {
          width: 1400 * (window.innerWidth / 1800),
          margin: (1400 * (window.innerWidth / 1800)) / 2,
        },
        jupiter: {
          width: 1159 * (window.innerWidth / 1800),
          margin: (1159 * (window.innerWidth / 1800)) / 2,
        },
        earth: {
          width: 859 * (window.innerWidth / 1800),
          margin: (859 * (window.innerWidth / 1800)) / 2,
        },
        sun: {
          width: 500 * (window.innerWidth / 1800),
          margin: (500 * (window.innerWidth / 1800)) / 2,
        },
      },
    };
    setDimensions(newDimensions);
  };

  useEffect(() => {
    window.addEventListener('resize', handleDimensions);
    return () => {
      window.removeEventListener('resize', handleDimensions);
    };
  }, []);

  const animFuns: Record<string, () => void> = {
    uranus: () => {
      const element = document.getElementsByClassName(
        `uranus-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.transform = `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${uranusCounter.current}deg) skew(0deg, 0deg)`;

        uranusCounter.current += 0.15655634344;
        requestAnimationFrame(animFuns.uranus);
      }
    },
    jupiter: () => {
      const element = document.getElementsByClassName(
        `jupiter-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.transform = `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${jupiterCounter.current}deg) skew(0deg, 0deg)`;

        jupiterCounter.current += 0.15655634344;
        requestAnimationFrame(animFuns.jupiter);
      }
    },
    earth: () => {
      const element = document.getElementsByClassName(
        `earth-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.transform = `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${earthCounter.current}deg) skew(0deg, 0deg)`;

        earthCounter.current += 0.15655634344;
        requestAnimationFrame(animFuns.earth);
      }
    },
    sun: () => {
      const element = document.getElementsByClassName(
        `sun-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.transform = `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${sunCounter.current}deg) skew(0deg, 0deg)`;

        sunCounter.current += 0.15655634344;
        requestAnimationFrame(animFuns.sun);
      }
    },
  };

  const setTimer = (key: string, count: number) => {
    const timerId = setTimeout(() => {
      const element = document.getElementsByClassName(
        `${key}-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.opacity = '1';
        animFuns[key]();
      }
    }, count);
    timers.current.push(timerId);
  };

  useEffect(() => {
    const cleanupTimers = timers.current;

    setTimeout(() => {
      const sun = sunRef.current;
      if (sun) {
        (sun as HTMLDivElement).style.opacity = '1';
      }
    }, 400);

    setTimeout(() => {
      const elements = document.getElementsByClassName(`electron`);
      if (elements) {
        for (let idx = 0; idx < elements.length; idx++) {
          const element = elements[idx] as HTMLElement;
          element.style.opacity = '1';
        }
      }
    }, 1200);

    let count = 600;
    const { orbitDimensions } = dimensions;
    const keys = Object.keys(orbitDimensions).reverse();
    keys.forEach((key) => {
      setTimer(key, count);
      count += 100;
    });

    return () => {
      cleanupTimers.forEach((timerId) => {
        clearTimeout(timerId);
      });
    };
  }, []);

  return (
    <div
      className="galaxy"
      style={{
        height: `${dimensions.galaxyHeight + 330}px`,
      }}
    >
      <div
        className="shade"
        style={{
          height: `${dimensions.orbitDimensions.earth.width}px`,
          width: `${dimensions.orbitDimensions.sun.width}px`,
        }}
      ></div>

      {Object.entries(dimensions.orbitDimensions).map(
        ([orbitName, orbitDims]) => (
          <div
            key={orbitName}
            className={`${orbitName}-orbit orbit`}
            style={{
              width: `${orbitDims.width}px`,
              height: `${orbitDims.width}px`,
              marginTop: `-${orbitDims.margin}px`,
              marginLeft: `-${orbitDims.margin}px`,
            }}
          >
            <div
              className="electron"
              style={{
                width: `${dimensions.electron}px`,
                height: `${dimensions.electron}px`,
              }}
            >
              <img
                src={
                  orbitName === 'uranus'
                    ? UranusElectronImage
                    : orbitName === 'jupiter'
                    ? JupiterElectronImage
                    : orbitName === 'earth'
                    ? EarthElectronImage
                    : SunElectronImage
                }
                className="electron"
                alt={`${orbitName}-electron`}
              />
            </div>
          </div>
        )
      )}

      <img
        ref={sunRef}
        src="/octi-learn.svg"
        alt="OctiLearn"
        className="logo-img"
        style={{ height: '8%' }}
      />
    </div>
  );
};

export default Orbit;
