import Facebook from '../../../assets/icons/Facebook.png';
import Twitter from '../../../assets/icons/Twitter.png';
import Instagram from '../../../assets/icons/Instagram.png';
import Linkedin from '../../../assets/icons/Linkedin.png';
import './style.css';
import { Link } from 'react-router-dom';
import Bg from '../../../assets/icons/BG.png';
import Beta from '../../../assets/icons/Beta.png';

function Footer() {
  const dynamicYear = new Date().getFullYear();
  return (
    <>
      <div className="horizontal-line"></div>
      <footer className="footer_container">
        <div className="py-4">
          <div className="footer-main-container">
            <div className="octi-img-social">
              <img className="octi-learn-logo" src="logo.svg" alt="" />
              <p className="my-4 experience-text">
                Experience the future of learning.
              </p>

              <div className="icons d-flex  justify-content-between align-items-center social-media-icon">
                <div>
                  <a
                    href="https://twitter.com/OctiLearn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="Twitter" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/octilearn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Instagram} alt="Instagram" />
                  </a>
                </div>

                <div>
                  <a
                    href="https://www.facebook.com/OctiLearn/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Facebook} alt="Facebook" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/company/octilearn/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="LinkedIn" />
                  </a>
                </div>
                {/* <div className=" px-1">
                <a href="https://www.facebook.com/OctiLearn/" target="_blank">
                  <Facebook width={15} height={20} />
                </a>
              </div> */}
              </div>
            </div>

            <div className="parent-beta">
              <div className="child-beta">
                <Link
                  to={`${process.env.REACT_APP_SIGNUP_URL}`}
                  target="_blank"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  <div className="border-div">
                    <div className="beta-container">
                      <div className="beta-container-1">
                        <img
                          className="inside-img"
                          // src="/insideBorder.png"
                          src={Bg}
                          alt="insideDiv"
                        />
                        <img src={Beta} className="beta" alt="beta" />
                      </div>
                    </div>
                    <div className="text-footer">
                      <p className="apply-text">
                        Currently in Public Beta. Officially launching soon.
                      </p>
                    </div>
                  </div>
                </Link>

                {/* <a
                  className="beta-link"
                  href="https://airtable.com/appp24e4POBGM4X9o/shrr3sIvV2Wjr2osF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 
                </a> */}

                <p className="footer-para">
                  We are still tuning the product and would love your help. Join
                  our Beta to help contribute.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-section">
        <h1>{dynamicYear} © Octilearn. All rights reserved.</h1>
      </div>
    </>
  );
}

export default Footer;
