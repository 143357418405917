import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Ambassador from '../pages/Ambassador/Ambassador';

import './App.css';

import Home from '../pages/Home';
import { Navbar, Footer } from '../components/common';
import Contact from '../pages/Contact/Contact';
import Faq from '../pages/Faq/Faq';
import AOS from 'aos';
import { useEffect } from 'react';
import About from '../pages/About/About';
import 'aos/dist/aos.css';
import ContactUs from '../pages/Contact-us/Contact-us';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const mixpanel = require('mixpanel-browser');
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  track_pageview: true,
  persistence: 'localStorage',
});

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        {/* <Route path="/contact" element={<Contact />}></Route>
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/ambassador" element={<Ambassador />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route> */}
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
