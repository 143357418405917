import React from 'react';
import './Banner.css';
import Circle from '../../../assets/home/Circle.png';

const Banner = ({ title, coloredText, details, list, image, revert }: any) => {
  return (
    <div className={`banner ${revert ? 'pr revert-gradiant' : 'pl gradient'}`}>
      <div className={`banner-content ${revert ? 'order-2' : 'order-1'}`}>
        <div
          style={{
            paddingTop: '26px',
          }}
        >
          <span className="banner-title">
            {title} <span className="banner-colorText">{coloredText}</span>
          </span>
        </div>

        <div className="banner-details">{details}</div>

        <div className="banner-list">
          {list?.map((item: any) => {
            return (
              // <div className="icon-wrapper" dir={revert ? 'RTL' : 'LTR'}>
              <div className="icon-wrapper">
                <img src={Circle} alt="Circle" />

                <span className={`banner-list-title`}>{item?.title}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className={`${revert ? 'order-1' : 'order-2'} banner-img`}>
        {image}
      </div>
    </div>
  );
};

export default Banner;
