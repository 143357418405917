import { currentScreen } from '../../../theme';
import './ContentBox.css'; // @ts-ignore
import FutureGenLine from '../../../assets/home/FutureGenLine.png';
import { useEffect, useState } from 'react';
interface ContentBoxProps {
  gradientTitle1: string;
  gradientTitle2: string;
  firstTitle: string;
  subFirstTitle: string;
  subFirstTitle1: string;
  subFirstTitle2: string;
  secondTitle: string;
  subSecondTitle: string;
  fontSize: number;
  top: number;
  fontWeight: number;
  gradient: boolean;
  lineTrue: boolean;
}

const defaultProps: Partial<ContentBoxProps> = {
  fontSize: 54,
  top: -30,
  fontWeight: 700,
  gradient: false,
};

function ContentBox({
  gradientTitle1,
  gradientTitle2,
  firstTitle,
  subFirstTitle,
  subFirstTitle1,
  subFirstTitle2,
  secondTitle,
  subSecondTitle,
  fontSize,
  top,
  fontWeight,
  gradient,
  lineTrue,
}: ContentBoxProps) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="contentBox">
      <div
        className="contentbox-title"
        data-aos="fade-left"
        data-aos-offset="400"
        data-aos-duration="3000"
        data-aos-easing="ease-in-shine"
      >
        <div className="paradiagm-title-width join-us-firstTitle margin-left full-width">
          <p className="contentbox-title-text paradiagm-title-content join-us-firstTitle">
            <p className="paradiagm-first-title-gradient">{gradientTitle1}</p>

            {firstTitle}
            {gradient ? (
              <span className="contentbox-title-text-gradient">
                {subFirstTitle}
              </span>
            ) : (
              <span>
                <p className="paradiagm-first-title-none-gradient">
                  {gradientTitle2}
                </p>
                {subFirstTitle}
              </span>
            )}
          </p>
          {subFirstTitle1 && (
            <p className="sub-firstTitle-first">{subFirstTitle1}</p>
          )}
          {subFirstTitle2 && (
            <p className="sub-firstTitle-second">{subFirstTitle2}</p>
          )}
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-duration="600"
        className="paradiagm-bottom-line"
      ></div>

      <div
        data-aos="fade-right"
        data-aos-offset="400"
        data-aos-duration="3000"
        data-aos-easing="ease-in-shine"
        className="contentbox-subtitle paradiagm-subtitle  join-us-secondTitle"
      >
        <div className="paradiagm-subtitle-width margin-left">
          <p className="contentbox-subtitle-text paradiagm-subtitle-text">
            {secondTitle}
            {screenWidth > 1200 && screenWidth > 768 && <br />}
            {subSecondTitle}
          </p>
        </div>
      </div>

      <div>
        {lineTrue && (
          <img
            src={FutureGenLine}
            style={{ width: '13.177083333333334vw', height: '5px' }}
          />
        )}
      </div>
    </div>
  );
}
ContentBox.defaultProps = defaultProps;
export default ContentBox;
