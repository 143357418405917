interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const defaultProps: Partial<Props> = {
  width: 40,
  height: 29,
};

function Youtube({ width, height }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
  <path d="M11.5018 9.40918V13.8628H17.6909C17.4191 15.2951 16.6036 16.5079 15.3804 17.3233L19.1127 20.2192C21.2872 18.212 22.5418 15.2638 22.5418 11.7616C22.5418 10.9461 22.4686 10.162 22.3327 9.4093L11.5018 9.40918Z" fill="url(#paint0_linear_1141_2248)"/>
  <path d="M5.05683 13.689L4.21507 14.3333L1.23547 16.6542C3.12774 20.4073 7.00609 23.0001 11.5015 23.0001C14.6064 23.0001 17.2096 21.9756 19.1124 20.2192L15.3801 17.3233C14.3555 18.0133 13.0487 18.4315 11.5015 18.4315C8.51153 18.4315 5.97115 16.4138 5.06153 13.6956L5.05683 13.689Z" fill="url(#paint1_linear_1141_2248)"/>
  <path d="M1.2355 6.3457C0.451452 7.89291 0.00195312 9.63885 0.00195312 11.4997C0.00195312 13.3606 0.451452 15.1065 1.2355 16.6537C1.2355 16.6641 5.06193 13.6847 5.06193 13.6847C4.83193 12.9947 4.69598 12.2629 4.69598 11.4996C4.69598 10.7363 4.83193 10.0045 5.06193 9.31455L1.2355 6.3457Z" fill="url(#paint2_linear_1141_2248)"/>
  <path d="M11.5017 4.57909C13.1954 4.57909 14.7008 5.16453 15.9031 6.29363L19.1963 3.00049C17.1994 1.13962 14.6068 0 11.5017 0C7.00633 0 3.12774 2.58227 1.23547 6.34592L5.06179 9.31501C5.97128 6.5968 8.51176 4.57909 11.5017 4.57909Z" fill="url(#paint3_linear_1141_2248)"/>
  <defs>
    <linearGradient id="paint0_linear_1141_2248" x1="21.9677" y1="20.2192" x2="11.7137" y2="19.6932" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D231A6"/>
      <stop offset="1" stop-color="#C2128C"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1141_2248" x1="18.1828" y1="23.0001" x2="1.68829" y2="21.4095" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D231A6"/>
      <stop offset="1" stop-color="#C2128C"/>
    </linearGradient>
    <linearGradient id="paint2_linear_1141_2248" x1="4.79881" y1="16.6538" x2="0.0895648" y2="16.5377" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D231A6"/>
      <stop offset="1" stop-color="#C2128C"/>
    </linearGradient>
    <linearGradient id="paint3_linear_1141_2248" x1="18.2623" y1="9.31501" x2="1.69172" y2="7.71018" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D231A6"/>
      <stop offset="1" stop-color="#C2128C"/>
    </linearGradient>
  </defs>
</svg>
  );
}
Youtube.defaultProps = defaultProps;
export default Youtube;
