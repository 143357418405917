interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const defaultProps: Partial<Props> = {
  width: 20,
  height: 20,
  color: "#FFFFFF",
};
function Hamburger({ width, height, color }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={48}
        d="M88 152h336M88 256h336M88 360h336"
      />
    </svg>
  );
}
Hamburger.defaultProps = defaultProps;
export default Hamburger;
