import { buttonBackground } from '../../../theme';
import './style.css';

interface ButtonStyle {
  width: number;
  height: number;
  borderRadius: number;
  className: string;
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  color: string;
  fontSize: number;
  text: string;
  hoverClassName: string;
  customPaddingClass?: string;
  onClick: () => void
}

const defaultProps: Partial<ButtonStyle> = {
  color: '#FFFFFF',
  height: 38,
  borderRadius: 40,
  paddingTop: 14,
  paddingBottom: 14,
  paddingLeft: 51,
  paddingRight: 51,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  fontSize: 17,
  text: 'Button',
  className: "",
  customPaddingClass: "",
  onClick: () => { },

};

function Button({
  borderRadius,
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  marginTop,
  height,
  color,
  fontSize,
  text,
  className,
  hoverClassName,
  customPaddingClass,
  onClick
}: ButtonStyle) {
  const buttonClasses = `secondary-button ${className || ''} ${customPaddingClass || ''}`;
  return (
    <button
      onClick={onClick}
      className={buttonClasses}
      onMouseEnter={(e) => e.currentTarget.classList.add(hoverClassName)}
      onMouseLeave={(e) => e.currentTarget.classList.remove(hoverClassName)}
    >
      {text}
    </button>
  );
}

Button.defaultProps = defaultProps;
export default Button;
