/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { forwardRef, useEffect, useState } from 'react';
import { HeroSection, Card } from '../../components/home';
import JoinUs from '../../components/home/JoinUs'; // @ts-ignore
import './style.css'; // @ts-ignore
import Assessment from '../../assets/home/Assessment';
import Content from '../../assets/home/Content';
import Banner from '../../components/home/Banner/Banner';
import ButtonHeader from '../../components/home/ButtonHeader/ButtonHeader';
import Orbit from '../../components/home/Orbit/Orbit';
import ReviewCard from '../../components/home/ReviewCard/ReviewCard';
import MapSvg from '../../assets/home/MapSvg';
import AnalyticsSvg from '../../assets/home/AnalyticsScg';
import FlashcardSvg from '../../assets/home/FlashcardSvg';
import NotesSvg from '../../assets/home/NotesSvg';
import Analytics from '../../assets/home/Analytics';
import AITutor from '../../assets/home/AITutor';
import Chatbot from '../../assets/home/Chatbot';
import AOS from 'aos';
import 'aos/dist/aos.css';

const NotesList = [
  {
    title: 'Filter sections like definitions and key concepts.',
  },
  {
    title: 'Edit our notes to create your personalised version.',
  },
  {
    title: 'Track your progress within the notes section easily.',
  },
];

const FCList = [
  {
    title: 'Filter sections like definitions and key concepts.',
  },
  {
    title: 'Move stacks to your personal library. ',
  },
  {
    title: 'Monitor your improvement as you complete stacks.',
  },
];

const AnalyticsList = [
  {
    title: 'Understand your standing in each sub-topic.',
  },

  {
    title: 'Focus on areas pulling your score down.',
  },

  {
    title: 'Improve faster by allocating time and effort smartly.',
  },
];

const cards = [
  {
    title: 'Interactive Content',
    subtitle: '',
    para: 'Personalize our content according to your needs.',
    icon: <Content />,
  },
  {
    title: 'Assessments',
    subtitle: '',
    para: 'Focus more on your areas of limitation.',
    icon: <Assessment />,
  },
  {
    title: 'Analytics',
    subtitle: '',
    para: 'Understand your strengths and limitations.',
    icon: <Analytics />,
  },
  {
    title: 'AI Tutor',
    subtitle: '',
    para: 'Overcome your learning gaps with our AI tutor.',
    icon: <AITutor />,
  },
];

function Home() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [animationShown, setAnimationShown] = useState(false);

  const handleMouseEnter = (index: any) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const cardsData = [
    {
      id: 1,
      name: 'Kennedy K',
      level: 'IGCSE Student',
      location: 'Ghana',
      description:
        'OctiLearn has been really useful for me. It’s an absolutely great app and great initiative to improve education for IGCSE students.',
      otherDetails: '',
    },

    {
      id: 2,
      name: 'Sarim H',
      level: 'O Level Student',
      location: 'Pakistan',
      description:
        'I’ve been using OctiLearn for 4 months. It’s been a game-changer for me. The platform is an entire ecosystem that provides everything you need.',
      otherDetails:
        'What really sets OctiLearn apart is its AI tutor, which adapts to my strengths and weaknesses.',
    },

    {
      id: 3,
      name: 'Khadija S',
      level: 'O Level Student',
      location: 'Pakistan',
      description:
        'There are so many little things which keep me engaged. The AI tutor was really helpful for my cousin too.',
      otherDetails:
        'I think OctiLearn is so unique in various aspects and offers great features.',
    },

    {
      id: 4,
      name: 'Abdullah S',
      level: 'IGCSE Student',
      location: 'Pakistan',
      description:
        'OctiLearn is superb. All the physics stuff with the simulations and flashcards helped a ton more than I thought they would. I can see this app having a great shot at becoming a mainstream app in the future !',
      otherDetails: '',
    },

    {
      id: 5,
      name: 'Tala T',
      level: 'IGCSE Student',
      location: 'Egypt',
      description:
        'OctiLearn is a real saver, especially for the students who find difficulties in organising their studies as it provides all resources, arranged in a fascinating way. The best part is that it helps me identify my strengths and weaknesses.',
      otherDetails: '',
    },

    {
      id: 6,
      name: 'Swaba L',
      level: 'IGCSE Student',
      location: 'India',
      description:
        'The OctiLearn app combines everything in one place so it’s a lot easier to have a productive study session. I really like how you can customize it to your fitting by editing the content.',
      otherDetails: '',
    },
  ];

  useEffect(() => {
    AOS.init({ once: true });

    const hasShownAnimation = localStorage.getItem('animationShown');

    const handleScroll = () => {
      if (animationShown) {
        localStorage.setItem('animationShown', 'true');
        setAnimationShown(false);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    if (!hasShownAnimation) {
      setAnimationShown(true);

      window.addEventListener('scroll', handleScroll);
    } else {
      setAnimationShown(false);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationShown]);

  return (
    <>
      <div className="home-content">
        <HeroSection />
        <div className="header-main-container">
          <div
            className="header-title-container"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-offset="100"
          >
            <div className="heading">
              <span className="header-title">OctiLearn Helps You </span>
              <span className="updated-colored-title">Learn Smarter</span>
            </div>
          </div>
          <div
            className="subtitle-container tradional-learning"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-offset={'100'}
          >
            <p className="traditional-model-subHeading">
              OctiLearn adapts to your needs to help you get better grades in
              less time.
            </p>
          </div>
        </div>
        <div>
          <div className="card-item-margin">
            <div className="row">
              {cards.map((card) => {
                return <Card {...card} />;
              })}
            </div>
          </div>
        </div>

        <div
          className="ecosystem-section"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="100"
        >
          <ButtonHeader
            title="The OctiLearn"
            coloredText="Ecosystem"
            text="All your IGCSE learning needs addressed in one place!"
          />

          <div
            style={{
              position: 'relative',
            }}
          >
            <Orbit />

            <div
              style={{
                position: 'absolute',
                top: '10%',
                zIndex: 100,
                width: '100%',
              }}
            >
              <Banner
                title="Interactive"
                coloredText="Notes"
                details="Say goodbye to PDFs and outdated webpages. Our interactive notes make
          learning faster and more efficient."
                list={NotesList}
                image={<NotesSvg />}
                revert={false}
              />
            </div>
          </div>

          <div
            className="banner-wrapper"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-offset="100"
          >
            <div data-aos="fade-up" data-aos-delay="500" data-aos-offset="100">
              <Banner
                title="Interactive"
                coloredText="Flashcards"
                details="Revise faster with flashcards designed to minimize your study time and help you perform better."
                list={FCList}
                image={<FlashcardSvg />}
                revert={true}
              />
            </div>

            <div data-aos="fade-up" data-aos-delay="500" data-aos-offset="100">
              <Banner
                title="Interactive"
                coloredText="Analytics"
                details="Review your detailed performance data to clearly understand your areas of strength and limitation."
                list={AnalyticsList}
                image={<AnalyticsSvg />}
                revert={false}
              />
            </div>
          </div>
        </div>

        <div
          className="ai-section"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="100"
        >
          <ButtonHeader
            title="Octavia"
            coloredText="Your AI Tutor!"
            text="Get instant academic support from our AI tutor, trained specifically for IGCSE."
            textTwo="Witness the future through instant feedback and support that adapt to you in real-time."
            font={true}
          />

          <div
            style={{
              minWidth: '100%',
              minHeight: '100%',
            }}
          >
            <Chatbot />
          </div>
        </div>

        <div data-aos="fade-up" data-aos-delay="500" data-aos-offset="100">
          <JoinUs />
        </div>

        <div
          className="join-us-mobile"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="100"
        >
          <div className="join-octi-learn">
            {/* <img className="degree-img" src="/join-1.png" alt="degreeImg" /> */}
            <img
              className="degree-img"
              src="/join-section-1.png"
              alt="degreeImg"
            />
            <div className="join-today-text">
              <p>Join OctiLearn Today!</p>
              <div className="join-text-p">
                <p className="join-text">
                  Experience the future of learning with our personalized and
                  adaptive ecosystem
                </p>
              </div>
              <div className="tradional-bottom-line-new"></div>
            </div>
            <div className="mobile-responsive-ball">
              <div className="ball-pair">
                <div className="small-ball">
                  <span className="tick">&#10003;</span>
                </div>
                <p className="personalizrd-text">Personalized Resources</p>
              </div>
              <div className="ball-pair">
                <div className="small-ball">
                  <span className="tick">&#10003;</span>
                </div>
                <p className="personalizrd-text">Learn Faster</p>
              </div>
            </div>
            <div className="higher-ball">
              <div className="round-ball">
                <span className="tick-check">&#10003;</span>
              </div>
              <p className="personal-text">Score Higher</p>
            </div>

            <div className="ball-container">
              <div className="ball-pair">
                <div className="small-ball">
                  <span className="tick">&#10003;</span>
                </div>
                <p className="personalizrd-text">Personalized Resources</p>
              </div>
              <div className="ball-pair">
                <div className="small-ball">
                  <span className="tick">&#10003;</span>
                </div>
                <p className="personalizrd-text">Learn Faster</p>
              </div>
              <div className="ball-pair">
                <div className="small-ball">
                  <span className="tick">&#10003;</span>
                </div>
                <p className="personalizrd-text">Score Higher</p>
              </div>
            </div>
            <div className="ball-container-1"></div>
            <div className="join-now-btn">
              <button className="now-btn">Join Us Now</button>
            </div>
            <div className="join-bulb-container">
              <img className="join-bulb" src="/join-2.png" alt="join bulb" />
            </div>
            <div className="book-container">
              <img className="book-img" src="/join-3-3.png" alt="book" />
            </div>
          </div>
        </div>

        <div
          className="card-section"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="100"
        >
          <div>
            <h1 className="card-section-heading">
              <span className="grey-color">OctiLearn</span> Is A Hit Among
              Students Around The World!{' '}
            </h1>

            <p className="card-section-text">
              Join today to take charge of your success and become part of our
              global student community.
            </p>
          </div>

          <div className="review-card-wrapper">
            {Array.from({ length: 3 }).map((_, rowIndex) => (
              <div className="group" key={rowIndex}>
                {cardsData
                  .slice(rowIndex * 2, rowIndex * 2 + 2)
                  .map((card, cardIndex) => (
                    <div
                      key={card.id}
                      onMouseEnter={() =>
                        handleMouseEnter(rowIndex * 4 + cardIndex)
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      <ReviewCard
                        active={activeIndex === rowIndex * 4 + cardIndex}
                        name={card?.name}
                        location={card?.location}
                        description={card?.description}
                        descriptionTwo={card?.otherDetails}
                        level={card?.level}
                      />
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>

        <div
          className="map-section"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-offset="100"
        >
          <div>
            <h1 className="heading">
              OctiLearn Users Come 60+ countries around the{' '}
              <span className="grey-color">Globe</span>{' '}
            </h1>

            <p className="card-section-text">
              Our mission is to empower learners around the world to achieve
              success.
            </p>
          </div>

          <MapSvg />
        </div>
      </div>
    </>
  );
}
export default forwardRef(Home);
