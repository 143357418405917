import React from 'react';
import ResponsiveImg from '../../UI/ResponsiveImage';
import ContentBox from '../ContentBox';
import { Button } from '../../UI';
import './joinus.css';
import { Link } from 'react-router-dom';

function JoinUs() {
  const joinUsContent = {
    firstTitle: 'Join OctiLearn Today!',
    secondTitle:
      'Embrace the future and witness the power of adaptive learning to achieve your best results. Say goodbye to outdated study methods that slow you down.',
    fontSize: 48,
    fontWeight: 600,
    paddingTop: 30,
  };
  return (
    <div className=" justify-content-center pb-5 pink-section">
      <div className="join-us position-relative mb-5 ">
        <ResponsiveImg
          width={250}
          position={{ top: '-75px', left: '25px', zIndex: '3' }}
          src="/join-section-1.png"
        />

        {/* <ResponsiveImg
          width={230}
          position={{ top: '368px', right: '66px', zIndex: '3' }}
          src="/join-section-2.png"
        />

        <ResponsiveImg
          width={320}
          position={{ top: '278px', left: '18px', zIndex: '2' }}
          src="/join-section-3.png"
        /> */}

        <ContentBox {...joinUsContent} top={20} />

        <ul className="d-flex list-unstyled m-0 p-0 list-styled">
          <li className="text-white">
            <span>&#10003;</span>
            <p>Personalise Content</p>
          </li>
          <li className="text-white">
            <span>&#10003;</span>
            <p>Identify Your Gaps</p>
          </li>
          <li className="text-white">
            <span>&#10003;</span>
            <p>Learn Faster</p>
          </li>
          <li className="text-white">
            <span>&#10003;</span>
            <p>Score Higher</p>
          </li>
        </ul>

        <br />

        <div className="d-flex justify-content-center mb-5">
          <Link to={`${process.env.REACT_APP_SIGNUP_URL}`} target="_blank">
            <Button
              className="gradient_button"
              text="Sign up now ~ its free!"
              marginTop={0}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default JoinUs;
