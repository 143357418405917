import React from 'react';
import './ReviewCard.css';
import User from '../../../assets/home/CardIcon.png';
import Activeuser from '../../../assets/home/CardIcon_Active.png';

const ReviewCard = ({
  active,
  name,
  location,
  description,
  level,
  descriptionTwo,
}: any) => {
  return (
    <div className={`${active ? 'review-card-active' : 'review-card'} `}>
      <div
        className={`${
          active ? 'review-card-content-active' : 'review-card-content'
        }`}
      >
        <div className="review-card-header">
          <img src={active ? Activeuser : User} alt="User" />

          <div>
            <span className="review-card-title">{name}</span>
            <br />
            <span className="review-card-title">{level}</span>
            <br />
            <span className="review-card-destination">{location}</span>
          </div>
        </div>

        <br />

        <div className="review-card-description">
          <p>{description}</p>
        </div>

        {descriptionTwo && (
          <div className="review-card-description">
            <p>{descriptionTwo}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
