import { currentScreen } from '../../../theme';
import './style.css';
import { useEffect, useState } from 'react';

interface ImgStyle {
  width: number;
  position?: boolean | Record<string, string>;
  src: string;
}

const ResponsiveImg = ({ width, src, position = false }: ImgStyle) => {
  const [dimensions, setDimensions] = useState<ImgStyle>({
    width: (window.innerWidth / 1349) * width,
    position,
    src,
  });

  const handleDimensions = () => {
    const newDimensions: ImgStyle = {
      width: (window.innerWidth / 1349) * width,
      position,
      src: src,
    };
    setDimensions(newDimensions);
  };

  useEffect(() => {
    window.addEventListener('resize', handleDimensions);
    return () => {
      window.removeEventListener('resize', handleDimensions);
    };
  });

  const CustomImage = () => {
    return (
      <img
        className={`responsive-image ${position ? 'position-absolute' : ''}`}
        src={src}
        alt="octi-learn"
        style={{
          width: `${dimensions.width}px`,
          ...(typeof position === 'object' ? { ...position } : {}),
        }}
      />
    );
  };

  return position ? (
    <CustomImage />
  ) : (
    <div
      className={`d-flex justify-content-center align-items-center w-100 my-5`}
    >
      <CustomImage />
    </div>
  );
};

export default ResponsiveImg;
