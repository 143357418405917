import { useNavigate } from 'react-router-dom';
import { Button } from '../../UI';
import './style.css';
import { useEffect, useState } from 'react';
import octilog from '../../../assets/logo.png';
import exit from '../../../assets/exit.png';
import { Link } from 'react-router-dom';
import hamburger from '../../../assets/mhamb.png';
import ActiveLink from '../../../assets/icons/Nav_Icon.png';

import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from '../../../assets/icons';
// const pages = [
//   { route: '/', label: 'Home' },
//   { route: '/about', label: 'About Us' },
//   { route: '/ambassador', label: 'Ambassador' },
//   { route: '/contact-us', label: 'Contact Us' },
//   { route: '/faq', label: 'FAQ' },
// ];
function Navbar() {
  const navigate = useNavigate();
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    setCurrentRoute(window.location.pathname);
  }, [window.location.pathname]);
  const [openMenu, setOpenMenu] = useState(false);
  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isBannerVisible && (
        <div className="I0AA-Banner">
          <p className="Banner-Text">
            Registrations for IOAA 2025 (Pakistan Team) are now open:{' '}
          </p>
          <a
            className="Banner-Button"
            href="https://airtable.com/appveCpW7mxgEd6S2/shrjX5MIwNZyj95Ay"
            target="_blank"
          >
            Click here to register
          </a>
          <button
            className="Banner-Close"
            onClick={() => setIsBannerVisible(false)}
          >
            x
          </button>
        </div>
      )}

      <div
        className="container-fluid nav_container"
        style={{
          padding: isBannerVisible ? '3rem' : '2rem 3rem',
        }}
      >
        <div className="logo_container">
          <img
            className="octi-learn-logo"
            src="logo.svg"
            alt=""
            style={{
              marginLeft: '-60px',
              width: '100%',
              minWidth: '120px',
            }}
          />
        </div>
        <div className="navigation_menu pt-md-4 pt-3">
          {/* <ul className="mt-2 d-md-flex d-none">
            {pages.map((page) => {
              return (
                <div className="nav_item">
                  <li
                    key={page.route}
                    onClick={() => navigate(page.route)}
                    className={page.route === currentRoute ? 'header-home' : ''}
                  >
                    {page.label}
                  </li>

                  {page?.route === currentRoute && (
                    <div className="nav_icon">
                      <div className="nav_circle" />

                      <div className="nav_bar" />
                    </div>
                  )}
                </div>
              );
            })}
          </ul> */}
          <div className={`icon_container hamburger-container  `}>
            <div onClick={handleMenuClick}>
              <img src={hamburger} />
            </div>
          </div>
        </div>

        <div className="hide-on-mobile nav_buttons">
          <Link to={`${process.env.REACT_APP_SIGNIN_URL}`} target="_blank">
            <button className="stroke-btn">Log In</button>
          </Link>

          <Link to={`${process.env.REACT_APP_SIGNUP_URL}`} target="_blank">
            <button className="gradient_button">Join Now</button>
          </Link>
        </div>
        <div
          className={`mobile-menu-container ${
            openMenu && isMobile ? 'd-flex' : 'd-none'
          }`}
        >
          <div
            className="exit-btn-div"
            onClick={() => {
              setOpenMenu(false);
            }}
          >
            <img src={exit} />
          </div>
          <div className="mobile-menu-main ">
            <div className="mobile-menu-top">
              <img src={octilog} alt="octilearn-logo" />
              <p className="mobile-menu-top-p">
                Reach the pinnacle of academic success with{' '}
                <span className="octi-name">OctiLearn’s </span>
                adaptive learning ecosystem.
              </p>
              {/* <a
              href="https://airtable.com/appp24e4POBGM4X9o/shrr3sIvV2Wjr2osF"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className="secondary-button"
                hoverClassName="secondary-button:hover-effect" // Specify the hover class
                text="Count Me In!"
                marginTop={40}
              />
            </a> */}
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <Link
                  to={`${process.env.REACT_APP_SIGNUP_URL}`}
                  target="_blank"
                >
                  <button
                    className="primary-button benefit-button1"
                    // onClick={props?.scrollToComponent}

                    style={{
                      background: '#BD23A2',
                    }}
                  >
                    Join Now
                  </button>
                </Link>

                <Link
                  to={`${process.env.REACT_APP_SIGNIN_URL}`}
                  target="_blank"
                >
                  <button
                    className="primary-button benefit-button1"
                    // onClick={props?.scrollToComponent}

                    style={{
                      background: '#BD23A2',
                      minWidth: '150px',
                      maxWidth: '150px',
                      padding: '14px 30px',
                    }}
                  >
                    Sign in
                  </button>
                </Link>
              </div>
              <hr className="menu-hr" />
            </div>
            {/* <ul className="navigation_menu-mobile">
              {pages.map((page) => {
                return (
                  <li
                    key={page.route}
                    onClick={() => {
                      navigate(page.route);
                      setOpenMenu(false);
                    }}
                    className={page.route === currentRoute ? 'header-home' : ''}
                  >
                    {page.label}
                  </li>
                );
              })}
            </ul> */}

            <div className="mobile-menu-footer-main">
              <div className="mobile-menu-footer-icon">
                <p>Follow us at</p>
                <div className="icons d-flex  justify-content-between align-items-center social-media-icon">
                  <div className=" ">
                    <a href="https://twitter.com/OctiLearn" target="_blank">
                      <Twitter width={25} height={19} />
                    </a>
                  </div>
                  <div className=" ">
                    <a
                      href="https://www.instagram.com/octilearn"
                      target="_blank"
                    >
                      <Instagram width={20} height={20} />
                    </a>
                  </div>

                  <div className="">
                    <a
                      href="https://www.facebook.com/OctiLearn/"
                      target="_blank"
                    >
                      <Facebook width={15} height={20} />
                    </a>
                  </div>
                  <div className="">
                    <a
                      href="https://www.linkedin.com/company/octilearn/"
                      target="_blank"
                    >
                      <Linkedin width={20} height={20} />
                    </a>
                  </div>
                  {/* <div className=" px-1">
                <a href="https://www.facebook.com/OctiLearn/" target="_blank">
                  <Facebook width={15} height={20} />
                </a>
              </div> */}
                </div>
              </div>
              <p className="mobile-menu-wait">
                We are still tuning the product and would love your help. Join
                our Beta to help contribute.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
