import React from 'react';

const AITutor = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8928 28.4286H4.64284C3.94193 28.4286 3.26969 28.15 2.77407 27.6545C2.27844 27.1589 2 26.4866 2 25.7857V4.64287C2 3.94193 2.27844 3.26972 2.77407 2.77407C3.26969 2.27844 3.94193 2 4.64284 2H25.7857C26.4866 2 27.1588 2.27844 27.6546 2.77407C28.1501 3.26972 28.4287 3.94193 28.4287 4.64287V12.5714"
        stroke="url(#paint0_linear_341_1546)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 22C9.66894 19.5719 12.3343 18 15.3375 18C17.4754 18 19.4421 18.7966 21 20.1316"
        stroke="url(#paint1_linear_341_1546)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2143 14.5536C17.4037 14.5536 19.1786 12.7787 19.1786 10.5893C19.1786 8.39986 17.4037 6.625 15.2143 6.625C13.0249 6.625 11.25 8.39986 11.25 10.5893C11.25 12.7787 13.0249 14.5536 15.2143 14.5536Z"
        stroke="url(#paint2_linear_341_1546)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8733 28.254C18.9459 28.0925 18.9459 26.7611 19.8733 26.5998C23.2334 26.0153 25.9058 23.456 26.6351 20.1244L26.691 19.869C26.8916 18.9524 28.1969 18.9467 28.4054 19.8615L28.4733 20.1591C29.2297 23.475 31.9027 26.0126 35.2536 26.5956C36.1857 26.7577 36.1857 28.096 35.2536 28.2582C31.9027 28.841 29.2297 31.3787 28.4733 34.6947L28.4054 34.9922C28.1969 35.9069 26.8916 35.9014 26.691 34.9846L26.6351 34.7293C25.9058 31.3977 23.2334 28.8383 19.8733 28.254Z"
        stroke="url(#paint3_linear_341_1546)"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_341_1546"
          x1="15.2143"
          y1="2"
          x2="15.2143"
          y2="28.4286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E015A2" />
          <stop offset="1" stopColor="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_341_1546"
          x1="14.5"
          y1="18"
          x2="14.5"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="#E015A2" />
          <stop offset="1" stopColor="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_341_1546"
          x1="15.2143"
          y1="6.625"
          x2="15.2143"
          y2="14.5536"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E015A2" />
          <stop offset="0.9999" stopColor="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_341_1546"
          x1="27.5652"
          y1="19.1785"
          x2="27.5652"
          y2="35.6752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E015A2" />
          <stop offset="1" stopColor="#624BA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AITutor;
