import React from 'react';

const Assessment = () => {
  return (
    <svg
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66667 28.4483C1.93333 28.4483 1.30578 28.1781 0.784 27.6375C0.262222 27.0969 0.000888889 26.4463 0 25.6857V6.3469C0 5.58716 0.261333 4.93701 0.784 4.39644C1.30667 3.85588 1.93422 3.58514 2.66667 3.58421H8.26667C8.55555 2.75541 9.03911 2.08776 9.71733 1.58127C10.3956 1.07478 11.1564 0.821533 12 0.821533C12.8436 0.821533 13.6049 1.07478 14.284 1.58127C14.9631 2.08776 15.4462 2.75541 15.7333 3.58421H21.3333C22.0667 3.58421 22.6947 3.85496 23.2173 4.39644C23.74 4.93793 24.0009 5.58808 24 6.3469V25.6857C24 26.4454 23.7391 27.096 23.2173 27.6375C22.6956 28.179 22.0676 28.4493 21.3333 28.4483H2.66667ZM5.33333 22.923H14.6667V20.1603H5.33333V22.923ZM5.33333 17.3976H18.6667V14.6349H5.33333V17.3976ZM5.33333 11.8723H18.6667V9.10958H5.33333V11.8723ZM12 5.31089C12.2889 5.31089 12.528 5.21281 12.7173 5.01666C12.9067 4.82051 13.0009 4.57325 13 4.27488C12.9991 3.97651 12.9044 3.72925 12.716 3.5331C12.5276 3.33695 12.2889 3.23888 12 3.23888C11.7111 3.23888 11.4724 3.33695 11.284 3.5331C11.0956 3.72925 11.0009 3.97651 11 4.27488C10.9991 4.57325 11.0938 4.82097 11.284 5.01805C11.4742 5.21512 11.7129 5.31273 12 5.31089Z"
        fill="url(#paint0_linear_341_366)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_341_366"
          x1="12"
          y1="0.821533"
          x2="12"
          y2="28.4483"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E015A2" />
          <stop offset="1" stop-color="#624BA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Assessment;
