import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const defaultProps: Partial<Props> = {
  width: 37,
  height: 31,
};

function Twitter({ width, height }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
    >
      <g clip-path="url(#clip0_363_3956)">
        <path
          d="M17.5646 1.82471H20.7975L13.7346 9.89722L22.0436 20.8821H15.5377L10.442 14.2198L4.61147 20.8821H1.3766L8.93114 12.2476L0.960266 1.82471H7.63133L12.2374 7.91428L17.5646 1.82471ZM16.43 18.947H18.2214L6.65794 3.65813H4.73558L16.43 18.947Z"
          fill="url(#paint0_linear_363_3956)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_363_3956"
          x1="20.9473"
          y1="20.8821"
          x2="1.37383"
          y2="19.7944"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D231A6" />
          <stop offset="1" stop-color="#C2128C" />
        </linearGradient>
        <clipPath id="clip0_363_3956">
          <rect
            width="23"
            height="23"
            fill="white"
            transform="translate(0.00195312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
Twitter.defaultProps = defaultProps;
export default Twitter;
