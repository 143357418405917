import './style.css';
import { Link } from 'react-router-dom';
import H_Background from '../../../assets/home/Main.png';
import Dashbaord from '../../../assets/home/Dashboard_1.png';
import Pink_Lines from '../../../assets/home/Lines.png';

const HeroSection = () => {
  return (
    <>
      <div className="wrapper">
        <img src={H_Background} alt="Background" className="bg_img" />

        <div className="details">
          <div className="joinList-container">
            <div className="joinList">
              <span>Join Students From</span>
              <span className="countires">60+ Countries 👋</span>
            </div>
          </div>

          <div className="content">
            <h1 className="welcome-design">Ace Your IGCSE Journey with</h1>
            <div className="tagline_container">
              <span>AI-Powered Adaptive Learning</span>

              <img src={Pink_Lines} alt="Lines" className="lines" />
            </div>

            <br />
            <br />

            <div className="hero-button-margin">
              <Link to={`${process.env.REACT_APP_SIGNUP_URL}`} target="_blank">
                <button
                  className="gradient_button"
                  style={{
                    minWidth: '230px !important',
                  }}
                >
                  Sign up now ~ its free!
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="dashboard"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-offset="100"
      >
        <img src={Dashbaord} alt="Dashboard" />
      </div>
    </>
  );
};

export default HeroSection;
