import React from 'react';
import './ButtonHeader.css';
import { Link } from 'react-router-dom';

const ButtonHeader = ({ title, coloredText, text, textTwo, font }: any) => {
  return (
    <div className="BH-Wrapper">
      <div>
        <span
          className="header-title"
          style={{
            fontSize: font && '50px',
          }}
        >
          {title}&nbsp;<span className="header-colorText">{coloredText}</span>
        </span>

        <div
          className="header-text"
          style={{
            fontSize: font && '24px',
            fontWeight: font && 400,
          }}
        >
          {text}
          <br />
          {textTwo}
        </div>
      </div>

      <Link to={`${process.env.REACT_APP_SIGNUP_URL}`} target="_blank">
        <button className="gradient_button">Sign up now ~ its free!</button>
      </Link>
    </div>
  );
};

export default ButtonHeader;
