import React from 'react';

const Analytics = () => {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.334 3.20288V7.3469V3.20288ZM25.334 11.4909V7.3469V11.4909ZM25.334 7.3469H29.334H25.334ZM25.334 7.3469H21.334H25.334Z"
        fill="url(#paint0_linear_341_1585)"
      />
      <path
        d="M25.334 3.20288V7.3469M25.334 7.3469V11.4909M25.334 7.3469H29.334M25.334 7.3469H21.334"
        stroke="url(#paint1_linear_341_1585)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 15.1639C26.5756 15.6866 25.0375 15.7807 23.5637 15.4353C22.0898 15.0898 20.7405 14.319 19.6721 13.212C18.6036 12.1051 17.8595 10.7072 17.5261 9.1803C17.1927 7.6534 17.2835 6.05988 17.788 4.58423H8C6.93913 4.58423 5.92172 5.02083 5.17157 5.79798C4.42143 6.57514 4 7.62919 4 8.72825V25.3043C4 26.4034 4.42143 27.4574 5.17157 28.2346C5.92172 29.0118 6.93913 29.4484 8 29.4484H24C25.0609 29.4484 26.0783 29.0118 26.8284 28.2346C27.5786 27.4574 28 26.4034 28 25.3043V15.1639ZM16 10.1096C16.3536 10.1096 16.6928 10.2551 16.9428 10.5142C17.1929 10.7732 17.3333 11.1246 17.3333 11.4909V22.5417C17.3333 22.908 17.1929 23.2594 16.9428 23.5184C16.6928 23.7775 16.3536 23.923 16 23.923C15.6464 23.923 15.3072 23.7775 15.0572 23.5184C14.8071 23.2594 14.6667 22.908 14.6667 22.5417V11.4909C14.6667 11.1246 14.8071 10.7732 15.0572 10.5142C15.3072 10.2551 15.6464 10.1096 16 10.1096ZM10.6667 14.2536C11.0203 14.2536 11.3594 14.3991 11.6095 14.6582C11.8595 14.9172 12 15.2686 12 15.635V22.5417C12 22.908 11.8595 23.2594 11.6095 23.5184C11.3594 23.7775 11.0203 23.923 10.6667 23.923C10.313 23.923 9.97391 23.7775 9.72386 23.5184C9.47381 23.2594 9.33333 22.908 9.33333 22.5417V15.635C9.33333 15.2686 9.47381 14.9172 9.72386 14.6582C9.97391 14.3991 10.313 14.2536 10.6667 14.2536ZM21.3333 18.3976C21.687 18.3976 22.0261 18.5432 22.2761 18.8022C22.5262 19.0613 22.6667 19.4126 22.6667 19.779V22.5417C22.6667 22.908 22.5262 23.2594 22.2761 23.5184C22.0261 23.7775 21.687 23.923 21.3333 23.923C20.9797 23.923 20.6406 23.7775 20.3905 23.5184C20.1405 23.2594 20 22.908 20 22.5417V19.779C20 19.4126 20.1405 19.0613 20.3905 18.8022C20.6406 18.5432 20.9797 18.3976 21.3333 18.3976Z"
        fill="url(#paint2_linear_341_1585)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_341_1585"
          x1="25.334"
          y1="3.20288"
          x2="25.334"
          y2="11.4909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E015A2" />
          <stop offset="1" stopColor="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_341_1585"
          x1="25.334"
          y1="3.20288"
          x2="25.334"
          y2="11.4909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E015A2" />
          <stop offset="1" stopColor="#624BA2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_341_1585"
          x1="16"
          y1="4.58423"
          x2="16"
          y2="29.4484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E015A2" />
          <stop offset="1" stopColor="#624BA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Analytics;
