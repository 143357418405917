interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const defaultProps: Partial<Props> = {
  width: 34,
  height: 34,
};

function Linkedin({ width, height }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
  <g clip-path="url(#clip0_981_3245)">
    <path d="M22.2234 0H1.77187C0.792187 0 0 0.741211 0 1.65762V21.3379C0 22.2543 0.792187 23 1.77187 23H22.2234C23.2031 23 24 22.2543 24 21.3424V1.65762C24 0.741211 23.2031 0 22.2234 0ZM7.12031 19.5994H3.55781V8.62051H7.12031V19.5994ZM5.33906 7.12461C4.19531 7.12461 3.27188 6.23965 3.27188 5.14805C3.27188 4.05645 4.19531 3.17148 5.33906 3.17148C6.47813 3.17148 7.40156 4.05645 7.40156 5.14805C7.40156 6.23516 6.47813 7.12461 5.33906 7.12461ZM20.4516 19.5994H16.8937V14.2627C16.8937 12.9914 16.8703 11.3518 15.0422 11.3518C13.1906 11.3518 12.9094 12.7398 12.9094 14.1729V19.5994H9.35625V8.62051H12.7687V10.1209H12.8156C13.2891 9.2584 14.4516 8.34648 16.1813 8.34648C19.7859 8.34648 20.4516 10.6195 20.4516 13.5754V19.5994Z" fill="url(#paint0_linear_981_3245)"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_981_3245" x1="22.752" y1="23" x2="0.463201" y2="21.8318" gradientUnits="userSpaceOnUse">
      <stop stop-color="#D231A6"/>
      <stop offset="1" stop-color="#C2128C"/>
    </linearGradient>
    <clipPath id="clip0_981_3245">
      <rect width="24" height="23" fill="white"/>
    </clipPath>
  </defs>
</svg>
  );
}
Linkedin.defaultProps = defaultProps;
export default Linkedin;
