interface Props {
  width?: number;
  height?: number;
}

const defaultProps: Partial<Props> = {
  width: 444,
  height: 573,
};
function IterativeIcon({ width, height }: Props) {
  return (
    <svg
     
      width={width}
      height={height}
      viewBox="0 0 444 573"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5_513)">
        <path
          d="M63.8339 196.909L81.4079 213.297C9.4079 290.835 13.7509 412.497 91.1759 484.697L74.7869 502.271C-12.4671 420.909 -17.3321 284.277 63.8339 196.909Z"
          fill="url(#paint0_linear_5_513)"
        />
      </g>
      <g filter="url(#filter1_d_5_513)">
        <path
          d="M74.5784 502.217L61.9494 515.759L43.6934 453.329L107.245 467.185L90.9714 484.637C163.801 555.477 280.529 557.474 355.74 487.637L372.129 505.212C332.173 542.427 279.582 563.091 224.979 563.029C168.842 563.106 114.886 541.29 74.5784 502.217Z"
          fill="url(#paint1_linear_5_513)"
        />
      </g>
      <mask
        id="mask0_5_513"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="48"
        y="0"
        width="348"
        height="348"
      >
        <path
          d="M48.8483 183.116L224.639 347.043L395.155 164.187L219.364 0.260299L48.8483 183.116Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5_513)">
        <path
          d="M356.045 206.728C433.568 279.02 437.824 400.905 365.533 478.428C293.241 555.952 171.358 560.208 93.834 487.916C16.3105 415.624 12.0542 293.74 84.3461 216.216C156.638 138.693 278.521 134.436 356.045 206.728ZM372.433 189.154C285.079 107.695 148.23 112.474 66.7716 199.828C-14.6869 287.181 -9.90873 424.031 77.4456 505.491C164.8 586.95 301.648 582.17 383.107 494.817C464.566 407.463 459.787 270.613 372.433 189.154Z"
          fill="url(#paint2_linear_5_513)"
        />
      </g>
      <g filter="url(#filter2_d_5_513)">
        <path
          d="M350.68 452.069L367.755 469.144C430.488 395.986 429.652 285.509 362.597 213.298L380.171 196.909C456.199 278.748 456.742 403.809 384.795 486.184L397.643 499.033L334.613 515.099L350.68 452.069Z"
          fill="url(#paint3_linear_5_513)"
        />
      </g>
      <g filter="url(#filter3_d_5_513)">
        <path
          d="M399.092 230.7L335.683 216.91L380.846 168.427L399.092 230.7Z"
          fill="#BC24A2"
        />
      </g>
      <g filter="url(#filter4_d_5_513)">
        <path
          d="M108.105 168.97L92.0385 232L45.0753 185.037L108.105 168.97Z"
          fill="url(#paint4_linear_5_513)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5_513"
          x="0.0136719"
          y="193.909"
          width="97.1621"
          height="317.362"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_513"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_513"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_5_513"
          x="37.6934"
          y="450.329"
          width="340.436"
          height="121.7"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_513"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_513"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_5_513"
          x="328.613"
          y="193.909"
          width="115.377"
          height="330.19"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_513"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_513"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_5_513"
          x="329.684"
          y="167.427"
          width="75.4082"
          height="74.2734"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_513"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_513"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_5_513"
          x="44.0762"
          y="165.97"
          width="75.0293"
          height="75.0293"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5" dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_513"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_513"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5_513"
          x1="48.5948"
          y1="502.271"
          x2="121.686"
          y2="482.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BD23A2" />
          <stop offset="1" stop-color="#A02FA2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_513"
          x1="43.6934"
          y1="453.329"
          x2="173.076"
          y2="637.237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BD23A2" />
          <stop offset="1" stop-color="#A02FA2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_513"
          x1="372.433"
          y1="189.154"
          x2="87.4496"
          y2="196.046"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BD23A2" />
          <stop offset="1" stop-color="#A02FA2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5_513"
          x1="334.613"
          y1="196.909"
          x2="454.76"
          y2="215.441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BD23A2" />
          <stop offset="1" stop-color="#A02FA2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5_513"
          x1="68.5569"
          y1="208.518"
          x2="108.392"
          y2="205.528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BD23A2" />
          <stop offset="1" stop-color="#A02FA2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
IterativeIcon.defaultProps = defaultProps;
export default IterativeIcon;
